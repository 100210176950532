<template>
    <div>
        <div id="wrapper">
            <div id="main">
                <div class="inner">
                    <div id="container06" class="container default full screen">
                        <div class="wrapper">
                        <div class="inner">
                            <img style="margin-bottom: 2.2em" width="150" src="../assets/images/logo.png" />
                            <h1 v-bind:style="`
                                font-size: ${$config.webConfig.body.titleSize}em;
                                text-shadow: 0px 1px 2px #ffffffaf;
                                line-height: 0.8em !important;
                                margin-top: 10px;
                            `" id="text24" v-html="`${$config.webConfig.body.title}`">

                            </h1>
                            <p id="text18">{{ $config.webConfig.body.description }}</p>
                            <h3 id="text23">
                                {{ $config.webConfig.body.date }}<br>
                                <span v-html="$config.webConfig.body.dateDescription" /><br><br>
                                <!-- <span style="font-size: 0.9em">
                                    Pendaftaran sisa
                                    <span style="color: blue">{{ dateBetween }} Hari lagi</span><br>
                                    ({{ $config.webConfig.body.registration.dateEnd.day }} {{ getMonthName() }} {{ $config.webConfig.body.registration.dateEnd.year }})
                                </span> -->
                            </h3>
                            <ul id="buttons01" class="buttons">
                            <li>
                                <a v-bind:href="$config.registration.direct_whatsapp" class="button animate__animated animate__pulse animate__infinite n01">
                                    <span class="label">Daftar Sekarang</span>
                                </a>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div id="container12">
                        <div class="wrapper">
                            <div class="inner">
                                <center>
                                    <img class="img-styled" :src="$config.webConfig.body.imageAfterTitle">
                                </center>
                            </div>
                        </div>
                    </div>
                    <div id="container12" class="container default full">
                        <div class="wrapper">
                            <div class="inner">
                                <h2 id="text39" style="text-shadow: 0px 0px 4px #ffffff;" class="style1">Tujuan</h2>
                                <p id="text44" class="style4">
                                    <span v-for="purpose in $config.webConfig.body.purpose" :key="purpose">
                                        {{ purpose }}
                                        <br><br>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="$config.webConfig.body.instruktur != undefined" id="container12" class="container default full">
                        <div class="wrapper">
                            <div class="inner">
                                <h2 id="text39" style="text-shadow: 0px 0px 4px #ffffff;" class="style1">Instruktur</h2>
                                <p id="text44" class="style4">
                                    <span v-html="$config.webConfig.body.instruktur"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="container12">
                        <div class="wrapper">
                            <div class="inner">
                                <carousel :loop="true" :paginationColor="'rgba(0, 0, 0, 0.5)'" :paginationActiveColor="'black'" :perPage="1" :autoplay="true">
                                    <slide v-for="img in $config.webConfig.body.imageList" :key="img" class="slide">
                                        <img :src="img">
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                    </div>
                    <div v-if="$config.webConfig.body.material != undefined" id="container12" class="container default full">
                        <div class="wrapper">
                            <div class="inner">
                                <h2 id="text39" style="text-shadow: 0px 0px 4px #ffffff;" class="style1">Materi</h2>
                                <p id="text44" class="style4">
                                    <ul style="list-style-type: decimal;" class="list-custom">
                                        <li v-for="material in $config.webConfig.body.material" :key="material">
                                            {{ material }}
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="$config.webConfig.body.imageAfterMaterial != undefined" id="container12">
                        <div class="wrapper">
                            <div class="inner">
                                <center>
                                    <img class="img-styled" :src="$config.webConfig.body.imageAfterMaterial">
                                </center>
                            </div>
                        </div>
                    </div>
                    <div v-if="$config.webConfig.body.dataAppend == null"></div>
                    <div v-else v-for="(data, index) in $config.webConfig.body.dataAppend" :key="index" id="container12" class="container default full">
                        <div class="wrapper">
                            <div class="inner">
                                <h2 id="text39" style="text-shadow: 0px 0px 4px #ffffff;" class="style1">{{ data.title }}</h2>
                                <p id="text44" class="style4">
                                    <span v-html="data.content" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="container03" class="container default full">
                        <div class="wrapper">
                        <div class="inner">
                            <h2 id="text04" style="text-align: center; text-shadow: 0px 0px 4px #ffffff;" class="style1">Biaya Registrasi</h2>
                            <p id="text27" class="style4">
                                <center>
                                    <span v-html="$config.webConfig.body.registration.price" style="font-size: 2.25em; font-weight: bold">
                                    </span><br>
                                    {{ $config.webConfig.body.registration.priceDescription }}
                                </center>
                            </p>
                        </div>
                        </div>
                    </div>
                    <div id="container12" class="container default full">
                        <div class="wrapper">
                            <div class="inner">
                                <h2 id="text39" style="text-shadow: 0px 0px 4px #ffffff;" class="style1">Fasilitas</h2>
                                <p id="text44" class="style4">
                                    <ul style="list-style-type: decimal;" class="list-custom">
                                        <li v-for="facility in $config.webConfig.body.facility" :key="facility">
                                            {{ facility }}
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="container06" class="container default full screen">
                        <div class="wrapper">
                        <div class="inner">
                            <h1 style="font-size: 4em; text-shadow: 0px 2px 2px #ffffffaf;" id="text24">
                                Kuota Terbatas
                            </h1>
                            <p id="text18">Tunggu Apalagi, Mari mendaftar sekarang.</p>
                            <ul id="buttons01" class="buttons">
                            <li>
                                <a v-bind:href="$config.registration.direct_formulir" class="button animate__animated animate__flash animate__infinite n01">
                                    <span class="label">Formulir Pendaftaran</span>
                                </a>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>

                    <div id="container04" class="container default full screen">
                        <div class="wrapper">
                        <div class="inner">
                            <p id="text16" class="style6">Intraina © All rights reserved</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dateBetween: "",
            }
        },
        methods: {
            getRangeDate() {
                var dateEnd = this.$config.webConfig.body.registration.dateEnd;

                const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds 
                const dateFirst = new Date(dateEnd.year, dateEnd.month, dateEnd.day);
                const dateLast  = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay());
                const diffDays = Math.round(Math.abs((dateFirst - dateLast) / oneDay));

                return diffDays;
            },
            getMonthName() {
                var month = this.$config.webConfig.body.registration.dateEnd.month;
                var listName = [
                    "Januari", "Febuari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"
                ];

                return listName[month - 1];
            }
        },
        mounted() {
            this.dateBetween = this.getRangeDate();
        }
    }
</script>

<style>
    ul.list-custom {
        list-style-type: square;
        padding-left: 20px;
    }
    .list-custom li {
        padding: 5px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    .img-styled {
        border-radius: 10px;
        box-shadow: 0px 0px 5px 1px #0a0a0a;
    }
</style>